// Copyright 2020 L. K. Post
// This file is licenced under the MIT licence, see `LICENCE` in the root directory.

// Styling for non-post pages
.layout-page {
  .summTitle a {
    color: inherit;
    border-bottom: none;
  }
  .summDate {
    line-height: 1em;
    font-size: 75%;
    font-style: italic;
    color: var(--secondary-color);
    transition: color $theme-time;
  }
}
