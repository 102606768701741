// Copyright 2020 L. K. Post
// This file is licenced under the MIT licence, see `LICENCE` in the root directory.

$left-width: 150px;
$right-width: $left-width * 1.5;

// Whitespace straddling main content, always maintained
$main-margin: 20px;

$main-content-textwidth: 725px + (2 * $main-margin);
$code-overflow-size: 150px;


// Calculated
$large-contentsize: $main-content-textwidth + $code-overflow-size;
$large-fullsize: $left-width + $large-contentsize + $right-width;

$medium-contentsize: $main-content-textwidth + $code-overflow-size;
$medium-fullsize: $left-width + $medium-contentsize;

$small-contentsize: $main-content-textwidth;
$small-fullsize: $small-contentsize;

// Large only
@mixin large {
  @media screen and (min-width: $large-fullsize) {
    @content;
  }
}

// Large or medium
@mixin medium-large {
  @media screen and (min-width: $medium-fullsize) {
    @content;
  }
}

// Medium only
@mixin medium {
  @media screen and (min-width: $medium-fullsize) and (max-width: $large-fullsize) {
    @content;
  }
}

// Medium or small
@mixin small-medium {
  @media screen and (min-width: $small-fullsize) and (max-width: $large-fullsize) {
    @content;
  }
}

// Small only
@mixin small {
  @media screen and (min-width: $small-fullsize) and (max-width: $medium-fullsize) {
    @content;
  }
}

// Small or undersized
@mixin undersized-small {
  @media screen and (max-width: $medium-fullsize) {
    @content;
  }
}

// Too small to fit full content, need to reflow
@mixin undersized {
  @media screen and (max-width: $small-fullsize) {
    @content;
  }
}

// Anything except undersized
@mixin regularsized {
  @media screen and (min-width: $small-fullsize) {
    @content;
  }
}

body {
  display: grid;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2em;
  margin-bottom: 2em;
}

.main {
  grid-area: main;
}

.title {
  grid-area: title;
}

.main, .title {
  margin-left: $main-margin;
  margin-right: $main-margin;
}

.mainnav {
  grid-area: mainnav;
}

.aside {
  grid-area: aside;
  //margin-left: 1em;
}

.subnav {
  display: none;
}

.logo {
  grid-area: logo;
}

@include regularsized {
  .main > *, .main > mjx-container {
    max-width: $main-content-textwidth;
  }
}

@include large {
  body {
    max-width: $large-fullsize;
    grid-template:
      "mainnav title empty2"
      "logo   main  aside"
      / $left-width $large-contentsize $right-width;
  }
  .subnav {
    display: initial;
  }
}

@include medium-large {
  .title, .mainnav {
    align-self: center;
  }
  .main > .highlighter-rouge {
    max-width: $main-content-textwidth + $code-overflow-size;
  }
  .logo img {
    width: $left-width / 2;
  }
  .mainnav a {
    display: block;
  }
  .mainnav, .logo {
    margin-left: 1em;
  }
}

@include medium {
  body {
    max-width: $medium-fullsize;
    grid-template:
      "mainnav title"
      "logo   main"
      "empty2  aside"
      / $left-width $medium-contentsize;
  }
}

@include small-medium {
}

@include small {
  body {
    max-width: $small-fullsize;
  }
}

@include undersized-small {
  body {
    grid-template:
      "title"
      "main"
      "aside"
      "logo"
      "mainnav";
  }
  body > * {
    margin-left: $main-margin;
    margin-right: $main-margin;
  }
  .mainnav {
    display: flex;
    justify-content: space-evenly;
  }
  .logo {
    text-align: center;
    img {
      height: 100px;
    }
  }
}

@include undersized {
  body {
    max-width: 100vw;
  }
  .main > *, .main > mjx-container {
    max-width: calc(100vw - #{$main-margin * 2});
  }
}
