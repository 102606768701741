// Copyright 2020 L. K. Post
// This file is licenced under the MIT licence, see `LICENCE` in the root directory.

// Core look
// Does not include responsive layout or text look-and-feel

// Main navigation
.mainnav a {
  border-bottom: none !important;
  color: inherit;
  font-weight: bold;
  text-decoration: none;
  margin-bottom: 0.5em;
  @include header-font;
  font-weight: 400;
  font-size: 150%;
}

// Logo
.logo a{
  border-bottom: none !important;
}

