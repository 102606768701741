// Copyright 2020 L. K. Post
// This file is licenced under the MIT licence, see `LICENCE` in the root directory.

@import "layout";

// Styling for posts only
.layout-post {

// make the very first content letter stand out
.main.big_first_letter > p:first-of-type::first-letter {
  font-size: 350%;
  float: left;
  padding-right: 10px;
  padding-bottom: 5px;
}

// Figure captions are placed in the left gutter, when available
.caption-fig {
  position: relative;
  figcaption {
    @include large {
      position: absolute;
      $gutter-width: $left-width - (2 * $main-margin);
      left: #{-($left-width - $main-margin)};
      top: 0;
      width: $gutter-width;
      text-align: right;
    }
    text-align: center;
    font-style: italic;
    font-size: 90%;
    color: var(--secondary-color);
    transition: color $theme-time;
    line-height: 1.5em;
  }
}

// In-post navigation
.subnav li {
  list-style-type: decimal;
  // The top-level nav element is toc-h2, so this hides everything deeper than 3.
  .toc-h5 {
    display: none;
  }
}

// Aside text
.aside > p {
  font-style: italic;
  // Make sure gl / gh icons are italic too. Looks a bit weird, but better than regular.
  i {
    font-style: italic;
  }
}

.aside {
  .licence, .licence:hover {
    border-bottom: none;
    display: block;
    text-align: center;
  }
}

} // End of .layout-post
