// Copyright 2020 L. K. Post
// This file is licenced under the MIT licence, see `LICENCE` in the root directory.

@import "reset";

@mixin text-font {
  font-family: 'Merriweather', serif;
  font-display: fallback;
}
@mixin header-font {
  font-family: 'Lato', sans-serif;
  font-display: fallback;
}
@mixin monospace-font {
  font-family: monospace, monospace;
  font-display: fallback;
}

html.light {
  --primary-color: black;
  --secondary-color: dimgray;
  --link-color: navy;
  --link-hover-color: darkslategray;
  --background-color: white;
  --background-shade: #eee;
}
html.dark {
  --primary-color: white;
  --secondary-color: gainsboro;
  --link-color: azure;
  --link-hover-color: cornsilk;
  --background-color: black;
  --background-shade: #222;
}
$theme-time: 1s;

@import "layout";
@import "core";

@import "basic-styling";

@import "markdown";
@import "solarized";

@import "post";
@import "page";

* {
  box-sizing: border-box;
}

