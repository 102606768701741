// Copyright 2020 L. K. Post
// This file is licenced under the MIT licence, see `LICENCE` in the root directory.

.highlighter-rouge, .highlighter-rouge * {
  transition: background $theme-time, color $theme-time;
}

html.light {
  // Inline code snippet, should be the same as plain .rouge-code
  code.highlighter-rouge {
    background: #fdf6e3;
    color: #657b83;
  }

  // Line numbers
  // Background should be taken from `.hll`, color should be taken from `.c`
  .rouge-gutter {
    background-color: #eee8d5;
    color: #93a1a1;
  }

  // Generated with `pygmentize -f html -S solarized-light -a .rouge-code`
  // Last updated: pygmentize 2.4.2
  .rouge-code .hll { background-color: #eee8d5 }
  .rouge-code  { background: #fdf6e3; color: #657b83 }
  .rouge-code .c { color: #93a1a1; font-style: italic } /* Comment */
  .rouge-code .err { color: #657b83; background-color: #dc322f } /* Error */
  .rouge-code .esc { color: #657b83 } /* Escape */
  .rouge-code .g { color: #657b83 } /* Generic */
  .rouge-code .k { color: #859900 } /* Keyword */
  .rouge-code .l { color: #657b83 } /* Literal */
  .rouge-code .n { color: #657b83 } /* Name */
  .rouge-code .o { color: #93a1a1 } /* Operator */
  .rouge-code .x { color: #657b83 } /* Other */
  .rouge-code .p { color: #657b83 } /* Punctuation */
  .rouge-code .ch { color: #93a1a1; font-style: italic } /* Comment.Hashbang */
  .rouge-code .cm { color: #93a1a1; font-style: italic } /* Comment.Multiline */
  .rouge-code .cp { color: #d33682 } /* Comment.Preproc */
  .rouge-code .cpf { color: #93a1a1 } /* Comment.PreprocFile */
  .rouge-code .c1 { color: #93a1a1; font-style: italic } /* Comment.Single */
  .rouge-code .cs { color: #93a1a1; font-style: italic } /* Comment.Special */
  .rouge-code .gd { color: #dc322f } /* Generic.Deleted */
  .rouge-code .ge { color: #657b83; font-style: italic } /* Generic.Emph */
  .rouge-code .gr { color: #dc322f } /* Generic.Error */
  .rouge-code .gh { color: #657b83; font-weight: bold } /* Generic.Heading */
  .rouge-code .gi { color: #859900 } /* Generic.Inserted */
  .rouge-code .go { color: #657b83 } /* Generic.Output */
  .rouge-code .gp { color: #657b83 } /* Generic.Prompt */
  .rouge-code .gs { color: #657b83; font-weight: bold } /* Generic.Strong */
  .rouge-code .gu { color: #657b83; text-decoration: underline } /* Generic.Subheading */
  .rouge-code .gt { color: #268bd2 } /* Generic.Traceback */
  .rouge-code .kc { color: #2aa198 } /* Keyword.Constant */
  .rouge-code .kd { color: #2aa198 } /* Keyword.Declaration */
  .rouge-code .kn { color: #cb4b16 } /* Keyword.Namespace */
  .rouge-code .kp { color: #859900 } /* Keyword.Pseudo */
  .rouge-code .kr { color: #859900 } /* Keyword.Reserved */
  .rouge-code .kt { color: #b58900 } /* Keyword.Type */
  .rouge-code .ld { color: #657b83 } /* Literal.Date */
  .rouge-code .m { color: #2aa198 } /* Literal.Number */
  .rouge-code .s { color: #2aa198 } /* Literal.String */
  .rouge-code .na { color: #657b83 } /* Name.Attribute */
  .rouge-code .nb { color: #268bd2 } /* Name.Builtin */
  .rouge-code .nc { color: #268bd2 } /* Name.Class */
  .rouge-code .no { color: #268bd2 } /* Name.Constant */
  .rouge-code .nd { color: #268bd2 } /* Name.Decorator */
  .rouge-code .ni { color: #268bd2 } /* Name.Entity */
  .rouge-code .ne { color: #268bd2 } /* Name.Exception */
  .rouge-code .nf { color: #268bd2 } /* Name.Function */
  .rouge-code .nl { color: #268bd2 } /* Name.Label */
  .rouge-code .nn { color: #268bd2 } /* Name.Namespace */
  .rouge-code .nx { color: #657b83 } /* Name.Other */
  .rouge-code .py { color: #657b83 } /* Name.Property */
  .rouge-code .nt { color: #268bd2 } /* Name.Tag */
  .rouge-code .nv { color: #268bd2 } /* Name.Variable */
  .rouge-code .ow { color: #859900 } /* Operator.Word */
  .rouge-code .w { color: #657b83 } /* Text.Whitespace */
  .rouge-code .mb { color: #2aa198 } /* Literal.Number.Bin */
  .rouge-code .mf { color: #2aa198 } /* Literal.Number.Float */
  .rouge-code .mh { color: #2aa198 } /* Literal.Number.Hex */
  .rouge-code .mi { color: #2aa198 } /* Literal.Number.Integer */
  .rouge-code .mo { color: #2aa198 } /* Literal.Number.Oct */
  .rouge-code .sa { color: #2aa198 } /* Literal.String.Affix */
  .rouge-code .sb { color: #2aa198 } /* Literal.String.Backtick */
  .rouge-code .sc { color: #2aa198 } /* Literal.String.Char */
  .rouge-code .dl { color: #2aa198 } /* Literal.String.Delimiter */
  .rouge-code .sd { color: #93a1a1 } /* Literal.String.Doc */
  .rouge-code .s2 { color: #2aa198 } /* Literal.String.Double */
  .rouge-code .se { color: #2aa198 } /* Literal.String.Escape */
  .rouge-code .sh { color: #2aa198 } /* Literal.String.Heredoc */
  .rouge-code .si { color: #2aa198 } /* Literal.String.Interpol */
  .rouge-code .sx { color: #2aa198 } /* Literal.String.Other */
  .rouge-code .sr { color: #cb4b16 } /* Literal.String.Regex */
  .rouge-code .s1 { color: #2aa198 } /* Literal.String.Single */
  .rouge-code .ss { color: #2aa198 } /* Literal.String.Symbol */
  .rouge-code .bp { color: #268bd2 } /* Name.Builtin.Pseudo */
  .rouge-code .fm { color: #268bd2 } /* Name.Function.Magic */
  .rouge-code .vc { color: #268bd2 } /* Name.Variable.Class */
  .rouge-code .vg { color: #268bd2 } /* Name.Variable.Global */
  .rouge-code .vi { color: #268bd2 } /* Name.Variable.Instance */
  .rouge-code .vm { color: #268bd2 } /* Name.Variable.Magic */
  .rouge-code .il { color: #2aa198 } /* Literal.Number.Integer.Long */
}

html.dark {
  // Inline code snippet, should be the same as plain .rouge-code
  code.highlighter-rouge {
    background: #002b36;
    color: #839496;
  }

  // Line numbers
  // Background should be taken from `.hll`, color should be taken from `.c`
  .rouge-gutter {
    background-color: #073642;
    color: #586e75;
  }

  // Generated with `pygmentize -f html -S solarized-dark -a .rouge-code`
  // Last updated: pygmentize 2.4.2
  .rouge-code .hll { background-color: #073642 }
  .rouge-code  { background: #002b36; color: #839496 }
  .rouge-code .c { color: #586e75; font-style: italic } /* Comment */
  .rouge-code .err { color: #839496; background-color: #dc322f } /* Error */
  .rouge-code .esc { color: #839496 } /* Escape */
  .rouge-code .g { color: #839496 } /* Generic */
  .rouge-code .k { color: #859900 } /* Keyword */
  .rouge-code .l { color: #839496 } /* Literal */
  .rouge-code .n { color: #839496 } /* Name */
  .rouge-code .o { color: #586e75 } /* Operator */
  .rouge-code .x { color: #839496 } /* Other */
  .rouge-code .p { color: #839496 } /* Punctuation */
  .rouge-code .ch { color: #586e75; font-style: italic } /* Comment.Hashbang */
  .rouge-code .cm { color: #586e75; font-style: italic } /* Comment.Multiline */
  .rouge-code .cp { color: #d33682 } /* Comment.Preproc */
  .rouge-code .cpf { color: #586e75 } /* Comment.PreprocFile */
  .rouge-code .c1 { color: #586e75; font-style: italic } /* Comment.Single */
  .rouge-code .cs { color: #586e75; font-style: italic } /* Comment.Special */
  .rouge-code .gd { color: #dc322f } /* Generic.Deleted */
  .rouge-code .ge { color: #839496; font-style: italic } /* Generic.Emph */
  .rouge-code .gr { color: #dc322f } /* Generic.Error */
  .rouge-code .gh { color: #839496; font-weight: bold } /* Generic.Heading */
  .rouge-code .gi { color: #859900 } /* Generic.Inserted */
  .rouge-code .go { color: #839496 } /* Generic.Output */
  .rouge-code .gp { color: #839496 } /* Generic.Prompt */
  .rouge-code .gs { color: #839496; font-weight: bold } /* Generic.Strong */
  .rouge-code .gu { color: #839496; text-decoration: underline } /* Generic.Subheading */
  .rouge-code .gt { color: #268bd2 } /* Generic.Traceback */
  .rouge-code .kc { color: #2aa198 } /* Keyword.Constant */
  .rouge-code .kd { color: #2aa198 } /* Keyword.Declaration */
  .rouge-code .kn { color: #cb4b16 } /* Keyword.Namespace */
  .rouge-code .kp { color: #859900 } /* Keyword.Pseudo */
  .rouge-code .kr { color: #859900 } /* Keyword.Reserved */
  .rouge-code .kt { color: #b58900 } /* Keyword.Type */
  .rouge-code .ld { color: #839496 } /* Literal.Date */
  .rouge-code .m { color: #2aa198 } /* Literal.Number */
  .rouge-code .s { color: #2aa198 } /* Literal.String */
  .rouge-code .na { color: #839496 } /* Name.Attribute */
  .rouge-code .nb { color: #268bd2 } /* Name.Builtin */
  .rouge-code .nc { color: #268bd2 } /* Name.Class */
  .rouge-code .no { color: #268bd2 } /* Name.Constant */
  .rouge-code .nd { color: #268bd2 } /* Name.Decorator */
  .rouge-code .ni { color: #268bd2 } /* Name.Entity */
  .rouge-code .ne { color: #268bd2 } /* Name.Exception */
  .rouge-code .nf { color: #268bd2 } /* Name.Function */
  .rouge-code .nl { color: #268bd2 } /* Name.Label */
  .rouge-code .nn { color: #268bd2 } /* Name.Namespace */
  .rouge-code .nx { color: #839496 } /* Name.Other */
  .rouge-code .py { color: #839496 } /* Name.Property */
  .rouge-code .nt { color: #268bd2 } /* Name.Tag */
  .rouge-code .nv { color: #268bd2 } /* Name.Variable */
  .rouge-code .ow { color: #859900 } /* Operator.Word */
  .rouge-code .w { color: #839496 } /* Text.Whitespace */
  .rouge-code .mb { color: #2aa198 } /* Literal.Number.Bin */
  .rouge-code .mf { color: #2aa198 } /* Literal.Number.Float */
  .rouge-code .mh { color: #2aa198 } /* Literal.Number.Hex */
  .rouge-code .mi { color: #2aa198 } /* Literal.Number.Integer */
  .rouge-code .mo { color: #2aa198 } /* Literal.Number.Oct */
  .rouge-code .sa { color: #2aa198 } /* Literal.String.Affix */
  .rouge-code .sb { color: #2aa198 } /* Literal.String.Backtick */
  .rouge-code .sc { color: #2aa198 } /* Literal.String.Char */
  .rouge-code .dl { color: #2aa198 } /* Literal.String.Delimiter */
  .rouge-code .sd { color: #586e75 } /* Literal.String.Doc */
  .rouge-code .s2 { color: #2aa198 } /* Literal.String.Double */
  .rouge-code .se { color: #2aa198 } /* Literal.String.Escape */
  .rouge-code .sh { color: #2aa198 } /* Literal.String.Heredoc */
  .rouge-code .si { color: #2aa198 } /* Literal.String.Interpol */
  .rouge-code .sx { color: #2aa198 } /* Literal.String.Other */
  .rouge-code .sr { color: #cb4b16 } /* Literal.String.Regex */
  .rouge-code .s1 { color: #2aa198 } /* Literal.String.Single */
  .rouge-code .ss { color: #2aa198 } /* Literal.String.Symbol */
  .rouge-code .bp { color: #268bd2 } /* Name.Builtin.Pseudo */
  .rouge-code .fm { color: #268bd2 } /* Name.Function.Magic */
  .rouge-code .vc { color: #268bd2 } /* Name.Variable.Class */
  .rouge-code .vg { color: #268bd2 } /* Name.Variable.Global */
  .rouge-code .vi { color: #268bd2 } /* Name.Variable.Instance */
  .rouge-code .vm { color: #268bd2 } /* Name.Variable.Magic */
  .rouge-code .il { color: #2aa198 } /* Literal.Number.Integer.Long */
}