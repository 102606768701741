// Copyright 2020 L. K. Post
// This file is licenced under the MIT licence, see `LICENCE` in the root directory.

// Style applied *only* to Markdown content
.markdown {

// Make sure all top-level elements have at least *some* spacing between them
> * {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

// Link icon for headers
// Manually styling it like FontAwesome, because we can't really control what is generated by
// https://github.com/toshimaru/jekyll-toc#inject_anchors-filter
@include large {
  h1, h2, h3, h4, h5, h6 {
    .octicon-link {
      // Style common to all FA icons
      font-weight: 900;
      font-family: "Font Awesome 5 Free";
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;

      // Our own styling
      font-size: 50%;
      line-height: 2; // to make sure we can center the content, we must compensate for `font-size`.
      vertical-align: middle;
      margin-left: -1.5em;
      opacity: 0;
      // As we place a 1em element 1.5em to the left, there will be a 0.5em gap where the icon will disappear
      // when you move your cursor from the header to the link icon. This prevents that gap.
      width: 1.5em;
    }

    &:hover .octicon-link {
      opacity: 0.5;
    }

    .octicon-link::before {
      // Code point for `link` icon
      content: "\f0c1";
    }
  }
}

// Footnotes
a.footnote {
  &:before {
    content: "[";
  }
  &:after {
    content: "]";
  }
}

div.footnotes {
  &:before {
    content: "Footnotes";
    // We're faking being a h2
    display: block;
    @include header-font;
    font-size: 2em;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  li > * {
    padding-left: 1em;
  }
}

// Code highlighter, general
.highlighter-rouge {
  @include monospace-font;
}

// Large code blocks
.highlighter-rouge {
  code {
    display: block;
    overflow-x: auto;
  }
  .rouge-gutter {
    padding: 1em;
    font-style: italic;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .rouge-code {
    width: 100%;
    padding: 1em;
  }
}

// Inline code blocks
code.highlighter-rouge {
  padding: 0.25em;
}

// LaTeX
// Create scrollbar for formulas which are too wide
mjx-container {
  overflow-y: hidden; // Shows x-scrollbar, hides y-scrollbar
}

// This only affects top-level tables as it is the only variant used by markdown, and the code highlighter also uses table tags...
> table {
  padding: 0;
  tr {
    border-top: 1px solid var(--secondary-color);
    background-color: var(--background-color);
    transition: border-color $theme-time, background-color $theme-time;
    margin: 0;
    padding: 0;
    &:nth-child(2n) {
      background-color: var(--background-shade);
    }
    th {
      font-family: sans-serif;
      font-weight: bold;
      border: 1px solid var(--secondary-color);
      transition: border-color $theme-time;
      text-align: left;
      margin: 0;
      padding: 6px 13px;
    }
    td {
      border: 1px solid var(--secondary-color);
      transition: border-color $theme-time;
      text-align: left;
      margin: 0;
      padding: 6px 13px;
    }
    th :first-child, td :first-child {
      margin-top: 0;
    }
    th :last-child, td :last-child {
      margin-bottom: 0;
    }
  }
}

// Support for proper image placement
// TODO: limit size?
img[src$="#center"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

img[src$="#left"] {
  margin: 0.5em 0.5em 0.5em 0;
  float: left;
}
img[src$="#right"] {
  margin: 0.5em 0.5em 0.5em 0.5em;
  float: right;
}

} // End of .markdown
