// Copyright 2020 L. K. Post
// This file is licenced under the MIT licence, see `LICENCE` in the root directory.

// Basic style elements

// All text
body {
  color: var(--primary-color);
  background: var(--background-color);
  transition: color $theme-time, background $theme-time;
  @include text-font;
}

// Basic text decoration
strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

small {
  font-size: smaller;
}

blockquote {
  padding: 0.25em 1em;
  border-left: 0.7em solid var(--background-shade);
  border-right: 0.7em solid var(--background-shade);
  transition: border-color $theme-time;
  border-radius: 1em;
  font-style: italic;
}

// Make paragraphs readable
p {
  line-height: 1.5em;
  margin: 0.5em 0;
}

// Headers
h1 { font-size: 4em; }
h2 { font-size: 2em; }
h3 { font-size: 1.5em; }
h4 { font-size: 1.25em; }
h5 { font-size: 1.125em; }
h6 { font-weight: 600; }

h1, h2, h3, h4, h5, h6 {
  @include header-font;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 1em;
  margin-bottom: 0.5em;
}

// Links
a {
  color: var(--link-color);
  transition: color $theme-time;
  text-decoration: unset;
  border-bottom: 1px solid;
  &:hover {
    color: var(--link-hover-color);
    border-bottom: 1.5px solid;
  }
}

// Basic decorative elements
hr {
  width: 60%;
  margin-top: 3em;
  margin-bottom: 3em;
}

ul, ol {
  line-height: 1.5em;
  ul, ol {
    margin-left: 1em;
  }
  list-style-position: inside;
}

// Prevent images and iframes from escaping their container
img {
  max-width: 100%;
}

iframe {
  max-width: 100%;
}
